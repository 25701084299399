<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'contratantes.view',
                  id: id,
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              (contractor ? contractor.name + ' | ' : '') +
              $route.meta.title +
              (loading ? '' : ' (' + table_data.length + ')')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                v-on="on"
                color="warning"
                x-small
                class="ml-1"
                :to="{
                  name: 'contratantes.cpts_update',
                  id: id,
                }"
              >
                <v-icon> mdi-file </v-icon>
              </v-btn>
            </template>
            <span v-text="'Actualizar'" />
          </v-tooltip>
        </v-col>
        <v-col v-if="table_data.length > 0" cols="12" xs="12" md="12">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="table_headers"
            :search="table_search"
            :items="table_data"
            :loading="loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.key="{ item }">
              <b v-text="item.key" />
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
            <!-- <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    v-on="on"
                    :color="item.response ? 'primary' : 'warning'"
                    @click="dataDialog(item.id)"
                  >
                    <v-icon> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Detalle'" />
              </v-tooltip>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken } from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      id: this.$route.params.id,
      login: this.$store.getters.getLogin,
      loading: true,
      table_data: [],
      table_search: "",
      table_headers: [
        { text: "#", filterable: false, value: "key", width: "80" },
        { text: "Código", filterable: true, value: "cpt.code" },
        { text: "Descripción", filterable: true, value: "cpt.cpt" },
        { text: "Monto", filterable: true, value: "amount" },
        {
          text: "Sistema",
          filterable: true,
          value: "cpt.cpt_system.cpt_system",
        },
        {
          text: "Especialidad",
          filterable: true,
          value: "cpt.cpt_specialty.cpt_specialty",
        },
        // {
        //   text: "",
        //   fixed: true,
        //   value: "action",
        //   sortable: false,
        //   width: "50",
        // },
      ],
      contractor: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getTableData() {
      this.loading = true;
      this.table_data = [];

      Axios.get(
        URL_API + "/contractors/" + this.id + "/cpts",
        headersToken(this.login.token)
      ).then((response) => {
        this.table_data = response.data.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      URL_API + "/contractors/" + this.id,
      headersToken(this.login.token)
    ).then((response) => {
      this.contractor = response.data.data;
      this.getTableData();
    });
  },
};
</script>